// Breakpoints
$break-small: 767;
$break-large: 1430; // = container + x pixel.
$break-vertical: 767;

//variables spé
$HPRed: #d8383f;
$black: #1d1d1e;
$white: #ffffff;
$container-max-widths: (
  sm: 850px,
  md: 1000px,
  lg: 1400px,
  xl: 1440px,
);

.custom-container {
  @include make-container();
}
