@mixin make-container() {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @each $breakpoint, $max-width in $container-max-widths {
    @if $breakpoint == sm {
      @media (min-width: $break-small + 1px) {
        max-width: $max-width;
      }
    } @else if $breakpoint == md {
      @media (min-width: $break-small + 1px) {
        max-width: $max-width;
      }
    } @else if $breakpoint == lg {
      @media (min-width: $break-large + 1px) {
        max-width: $max-width;
      }
    } @else if $breakpoint == xl {
      @media (min-width: $break-large + 1px) {
        max-width: $max-width;
      }
    } @else if $breakpoint == xxl {
      @media (min-width: $break-large + 1px) {
        max-width: $max-width;
      }
    }
  }
}
