#cnil_banner_consent {
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50vh;
  left: 50vw;
  width: 800px;
  background-color: #1D1D1E;
  transition: all 0.3s ease-in;
  font-size: 1.3em;
  padding: 0 0 0 0;
  z-index: 1000;
  max-height: 90vh;
  overflow: auto;
  color: #FFF;
  font-family: "open_sansregular";

  @include RWD(mobile) {
    width: 100vw;
    height: max-content;
  }

  p {
    width: auto;
    display: inherit;
    margin: 0 1em;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 500;
  }

  .banner-title {
    text-transform: uppercase;
    font-family: "open_sansregular";
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #FFF;
    font-weight: 700;
    margin-bottom: 20px;
    padding: 25px 15px 0 15px;
    &__icon {
      display: inline-block;
      width: 22px;
      height: 22px;
      background: url(svg-bg-uri($svg-cookie, #FFF)) no-repeat;
      vertical-align: bottom;
    }
  }

  a {
    background-color: transparent;
    text-transform: none;
    text-decoration: underline;
    padding: 0;
    color: #FFF;
    font-size: 12px;
    font-family: "open_sansbold";
    &:hover,
    &:focus {
      color: $HPRed;
    }
  }

  .modalCookies {
    &__close {
      background: transparent url(svg-bg-uri($svg-close, #FFF)) no-repeat 50%
        50%;
      background-size: 15px;
      width: 30px;
      opacity: 1;
      height: 30px;
      border-radius: 0;
      border: 3px solid #FFF;
      position: absolute;
      right: 20px;
      top: 20px;
      right: 20px;
      transform: translate(0, 0);
    }

    &__btns {
      display: flex;
      justify-content: flex-end;
      margin: 25px 0;
      padding: 0 15px;

      .cookie-btn {
        border: 3px solid #FFF;
        color: #fff;
        border-radius: 0;
        font-family: "open_sansbold";
        text-transform: uppercase;
        @media screen and (max-width: 992px) {
          width: 35%;
        }
        @media screen and (max-width: 575px) {
          width: 72%;
        }
        &:first-child {
          margin-right: auto;
          @media screen and (max-width: 992px) {
            margin-right: initial;
            margin-bottom: 15px;
            width: 72%;
          }
        }
        &:last-child {
          margin-left: 15px;
          @media screen and (max-width: 575px) {
            margin-left: 0;
            margin-top: 15px;
          }
        }
      }

      @media screen and (max-width: 992px) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    &__all-choices {
      display: none;
      &__input {
        padding: 15px;
        border-top: 1px solid rgba(#000, 0.5);
        position: relative;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &__desc {
          position: relative;
          top: -20px;
          display: none;
          width: 100%;
          font-size: 14px;
          margin-top: 5px;
          font-weight: 400;
          color: #FFF;  
          transition: all 0.4s ease-out;
        }
        .input-desc-open {
          display: block;
          top: 0;
          animation: 0.3s linear animationHeight;
        }

        @keyframes animationHeight {
          0% {
            opacity: 0;
            top: -20px;
            height: 0;
          }

          100% {
            opacity: 100%;
            top: 0;
            height: 100%;
          }
        }

        label {
          width: calc(100% - 50px);
          font-size: 18px;
          font-weight: 500;
          font-family: "open_sansbold";
          text-transform: uppercase;
          color: #FFF;
          padding-left: 65px;
          height: 30px;
          line-height: 30px;
          position: relative;
          cursor: pointer;
          &:before {
            content: "";
            width: 15px;
            height: 15px;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            background-image: url(svg-bg-uri($svg-chevron2, #FFF));
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            transition: all 0.3s ease-in-out;
          }
        }

        input[type="checkbox"] {
          position: relative;
          border: 0;
          background-color: transparent;
          width: 50px;
          height: 26px;
          cursor: pointer;
          margin: 0;
          //CHECKBOX  ETAT INITIAL
          &:before {
            content: "";
            width: 50px;
            height: 26px;
            background-color: #e6e6e6;
            position: absolute;
            top: 0;
            left: 0;
          }
          &:after {
            content: "";
            width: 20px;
            height: 20px;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 3px;
            left: 3px;
            transition: all 0.3s ease;
          }

          //CHECKBOX  ETATT CHECK
          &:checked {
            &:after {
              background-color: #000;
              left: calc(100% - 25px);
            }
          }
        }
        &.input-open {
          label {
            &:before {
              top: 30%;
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }
      }

      &__validate {
        width: 100%;
        box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 60px;
        .cookie-btn {
          position: relative;
          border: 3px solid #FFF;
          color: #fff;
          max-height: 45px;
          margin-right: 15px;
          margin-left: inherit;
          text-transform: uppercase;
          font-family: "open_sansbold";
          border-radius: 0;
        }
      }

      &--active {
        display: block;
      }
    }
  }
}

.btn__enable-media {
  margin-bottom: 25px;
}

.cookies-consent__sticky {
  position: fixed;
  bottom: 2.5%;
  right: 2.5%;
  .btn {
    background-color: $HPRed;
    color: #fff;
    border-radius: 30px;
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 19px;
    font-weight: 700;
    padding: 10px 20px;
  }
}
