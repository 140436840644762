//FILTER PART
.page--agenda {
  .h2-builder {
    display: flex;
    justify-content: space-between;
    height: auto;
    margin-top: 4rem;
    margin-bottom: 10rem;

    @include RWD(tablet) {
      flex-direction: column;
      justify-content: center;
    }

    .innerContent {
      display: flex;
      align-items: center;
      gap: 2rem;
      margin-bottom: 0;

      @include RWD(tablet) {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
      }
    }
    .icon {
      margin-bottom: 0;

      @include RWD(tablet) {
        width: auto;
      }
    }
  }
  .filter {
    &__container {
      display: flex;
      align-items: center;
      gap: 3em;
      margin-left: auto;

      @include RWD(tablet) {
        margin: 0 auto;
      }

      @include RWD(mobile) {
        flex-direction: column;
      }
    }
    &__date {
      display: none;
    }
    &__period {
      form {
        display: flex;
        align-items: center;

        @include RWD(mobile) {
          flex-direction: column;
          gap: 1rem;
        }
      }
      label {
        font-weight: bold;
      }
      input {
        height: 38px;
        border: 2px solid $black;
        &[name="start_date"],
        &[name="end_date"] {
          margin: 0 1rem;
          text-align: center;

          &:hover {
            cursor: pointer;
          }
          &::-webkit-calendar-picker-indicator {
            display: none;
          }
          // placeholder
          &::placeholder {
            text-align: center;
          }
        }
      }

      button[type="submit"] {
        height: 38px;
        padding: 0 1rem;
        background-color: $black;
        color: $white;
        border: 2px solid $black;
        font-weight: bold;
        cursor: pointer;
        transition: 0.25s all ease;

        &:hover {
          background-color: $white;
          color: $black;
        }
      }
    }
  }
  .select-contain {
    position: relative;

    @include RWD(tablet) {
      margin-top: 0 !important;
    }

    .collection {
      &-select {
        border-width: 2px;
        height: 38px;
      }
      &-list {
        z-index: 20;
        border-width: 2px;
      }
    }

    .icon {
      font-size: 1.2rem !important;
    }
  }
}

// NEWS PART
.custom-container {
  .row {
    display: flex;
    flex-wrap: wrap;
    row-gap: 4rem;
  }
}

.actu {
  &--item {
    margin-bottom: 4rem;

    &:hover {
      .lock {
        position: absolute;
        width: 60px;
        height: 60px;
      }
    }
    &--wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      min-height: 400px;
      margin-bottom: 2rem;
      position: relative;

      @include RWD(tablet) {
        min-height: 300px;
      }

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border-width: 1px;
        border-style: solid;
        z-index: 0;
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: calc(180px - 8rem);
        background-color: white;
        z-index: 1;
        border-bottom-width: 1px;
        border-bottom-style: solid;
      }
    }

    .visu {
      width: 92%;
      margin: 0 auto;
      min-height: 180px;
      height: 180px;
      background-size: cover;
      background-position: center;
      transform: translateY(-2.25rem);
      z-index: 10;
    }

    &--content {
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: 1rem;
      padding: 1rem;
      z-index: 10;
      position: relative;

      .lock {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 37px;
        height: 37px;
        border-bottom: solid 12px;
        border-right: solid 12px;
        transition: 0.25s all ease;
      }

      .callToAction--pictoAfter {
        margin-bottom: 0;
        background-color: $black;
        color: $white;

        &:hover {
          color: $black;
          background-color: $white;
          border-color: $black;

          &:before {
            border-color: $black;
          }
        }

        @include RWD(tablet) {
          font-size: 16px;
          min-height: 30px;
          padding: 0 0.25rem;
          &:before {
            min-height: 30px;
            height: auto;
          }
        }
      }
    }

    .infos {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: auto;
      padding-right: 1rem;
      min-height: 34px;

      .categorie {
        margin-left: auto;
      }
    }

    .date {
      font-family: "open_sansbold";
      display: inline-block;
      font-size: 1.7rem;
      min-height: 34px;
    }

    .title {
      font-family: "open_sansbold";
      font-size: 1.7rem;
      font-weight: bold;
      margin-top: 2rem;
      text-transform: uppercase;
    }

    .text {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

// HP - AGENDA PART
.hp-section__container {
  .actu--item:nth-child(n + 5) {
    display: none;
  }
  .actu--item {
    &--wrapper {
      min-height: auto;
    }
    .title {
      margin-top: 1rem;
    }
    .date {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      min-height: 54.5px;
      span {
        &.period {
          font-size: 2rem;
        }
        &.hours {
          font-size: 1.2rem;
          font-weight: normal;
          font-family: "open_sansregular";
        }
      }
    }
  }
  .hp-section {
    &__dark {
      height: 150px;
      text-align: center;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      h2 {
        font-family: "loveloblack";
        font-size: 34px;
        vertical-align: middle;
        padding-top: 44px;
      }
    }
    &__wrapper {
      background-color: $white;
      padding-top: 8rem;
    }
    &__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      margin-bottom: 3rem;

      .icon {
        font-size: 4rem;
      }
    }
    &__link {
      height: 121px;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      margin-top: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        margin: 0 auto;
      }
    }
  }
}
