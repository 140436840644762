.Home-page .classeVideo {
  .btn__enable-media {
    margin-top: 1em;
    margin-left: 1em;
    pointer-events: auto;
    border: 3px solid #fff;
    color: #fff;
    border-radius: 0;
    font-family: "open_sansbold";
    text-transform: uppercase;
  }
}

.footerDesktop {
  .xDistribution {
    &__infos {
      max-width: 300px;
    }
  }
}
